<template>
    <section>
      <b-row>
        <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">
          <stat
            :variant="stat.variant"
            :icon="stat.icon"
            :name="stat.name"
            :value="stat.value"
            :unit="stat.unit"
          ></stat>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col>
          <bruna-element-loader :dataUrl="climaChart00" loadingStrategy="ALWAYS">
          </bruna-element-loader>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col sm="12">
          <ag-grid-table
            ref="historicoClima"
            :debug="true"
            :configUrl="historicoClimaConfig"
            :dataUrl="historicoClimaData"
            @gridReady="onGridReady"
            @getRowsLoaded="onGetRowsLoaded"
            :paginationPageSize="50"
          >
          </ag-grid-table>
        </b-col>
      </b-row>
    </section>
  </template>
          
          <script>
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  
  
  import Stat from '@/views/clients/base/components/Stat.vue'
  
  import useApiServices from '@/services/useApiServices.js';
  
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  
  
  
  import axios from "@axios";
  var qs = require('qs');
  
  import
  {
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  
    BOverlay,
    BSpinner
  
  
  } from "bootstrap-vue";
  
  
  
  export default {
    components: {
      AgGridTable,
      BRow,
      BCol,
      BButtonToolbar,
      BButtonGroup,
      BButton,
      BInputGroup,
      BFormInput,
      BFormGroup,
      BFormTextarea,
  
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
  
  
      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,
  
      Stat,
  
      BOverlay,
      BSpinner,
  
      BrunaElementLoader
  
  
  
  
  
    },
  
    methods: {
  
      debugChange(value)
      {
  
        console.log(value)
  
        let decoded = JSON.parse(value)
  
  
  
  
  
      },
  
  
  
  
  
      onGridReady(gridApi, gridColumnApi, params)
      {
  
        this.gridApi = gridApi
  
        console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
  
  
  
      },
  
      onGetRowsLoaded(gridApi, gridColumnApi, params)
      {
        console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })
  
  
  
      },
  
      loadStats00Data()
      {
  
        axios.request({
          method: "get",
          url: this.stats00Url,
          headers: {
            Accept: "application/json",
          },
        })
          .then((response) =>
          {
            this.stats = response.data
          })
  
  
  
  
  
      }
  
  
  
  
    },
  
  
  
    watch: {
      // whenever question changes, this function will run
      fet_name: {
        handler(newValue, oldValue)
        {
  
          let query = qs.stringify({ fet_name: newValue })
  
          console.log(query)
  
          this.historicoClimaConfig = useApiServices.historicoData.clima.historicoConfig + "?" + query
          this.historicoClimaData = useApiServices.historicoData.clima.historicoData + "?" + query
  
          this.climaChart00 = useApiServices.historicoData.clima.chart00 + "?" + query,
  
          this.stats00Url = useApiServices.historicoData.clima.stats00 + "?" + query,
  
  
          this.$refs['historicoClima'].refreshRows([], true);
          this.loadStats00Data();
  
        },
  
      }
    },
  
  
  
  
    props: {
  
      fet_name: {
        type: String,
        required: true,
        default: null
      },
  
  
    },
  
    mounted()
    {
  
      this.loadStats00Data();
  
  
    },
  
  
  
  
    created()
    {
  
  
    },
  
  
    data()
    {
  
      let query = qs.stringify({ fet_name: this.fet_name })
  
  
      return {
  
  
  
  
        showOverlay: false,
        overlayMessage: "Cargando...",
  
  
  
        gridApi: null,
  
        historicoClimaConfig: useApiServices.historicoData.clima.historicoConfig + "?" + query,
        historicoClimaData: useApiServices.historicoData.clima.historicoData + "?" + query,
        climaChart00: useApiServices.historicoData.clima.chart00 + "?" + query,
  
        stats00Url: useApiServices.historicoData.clima.stats00 + "?" + query,
  
        stats: [],
  
  
  
  
  
  
      };
    },
  };
          </script>
          <style lang="scss" scoped>
  span {
    font-size: 14px;
  }
  </style>
        
        
        <style lang="scss" >
  @import "@core/scss/vue/libs/vue-slider.scss";
  
  .escenario-base {
    background-color: #00bcd4 !important;
  }
  </style>
        
        <style lang="scss" scoped>
  .card {
    .card-title {
      margin-bottom: 1.5rem !important;
    }
  
    ::v-deep .card-header {
      .heading-elements {
        position: static;
        cursor: inherit;
  
        .list-inline {
          display: block;
  
          li {
            a {
              padding: 0;
            }
  
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  </style>
          